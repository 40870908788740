.parking-usage-wrapper {
  margin-bottom: 50px;
}

.replace-details {
  border-radius: 4px;
  background: #FFF;
  padding: 32px;
}

.usage-details-default ul {
  line-height: 1.6em;
  padding: 0 45px;
  margin: 0;
}

.usage-title-wrapper {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.usage-title {
  font-size: 20px;
  color: #18181B;
  font-family: 'Poppins', system-ui, Arial, sans-serif;
  font-weight: 500;
  display: inline-block;
  margin: 0;
  order: 1;
}

.usage-paragraph {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #222;
  margin: 0;
  order: 3;
}

.usage-error-search {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;
}

.usage-error,
.usage-search-error {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  color: #B91C1C;
}

.usage-svg-close {
  display: none;
  cursor: pointer;
}

.usage-search-error {
  font-size: 16px;
}

.usage-search-error {
  font-size: 14px;
}

.usage-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  padding: 1px;
  padding-right: 8px;
}

.usage-search:focus-within {
  border-color:#0052CC;
}

.usage-search input[type="text"]{
  padding: 8px 16px;
  font-size: 16px;
  color: #222;
  border: none;
}

.usage-search input[type="text"]:focus{
  outline: none;
}

.usage-table {
  margin-top: 8px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}

.usage-table-error,
.search-error {
  border-color: #B91C1C!important;
}

.usage-table-header {
  border-bottom: 1px solid #E5E5E5;
}

.usage-table-header,
.usage-table-body {
  display: grid;
  grid-template-columns: 0.05fr 1fr 0.2fr 0.3fr;
}

.usage-table-body:nth-child(even) {
  background-color: #F9F9F9;
}

.usage-table-header p{
  margin: 0;
  padding: 8px 20px;
  align-items: center;
  display: flex;
  align-items: center;
}

.usage-header-status,
.usage-header-date {
  justify-content: center;
}

.usage-header-date {
  column-gap: 4px;
}
  
.usage-status-pill {
  padding: 1px 8px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 700;
}

.usage-pill-active {
  border: 1px solid #047857;
  background: #ECFDF5;
  color: #047857;
}

.usage-pill-schedule {
  border: 1px solid #FACC15;
  background: #FEFCE8;
  color: #854D0E;
}

.usage-radio-wrapper,
.usage-job-title,
.usage-status-wrapper,
.usage-date {
  margin: 0;
  padding: 8px 20px;
  align-items: center;
}

.usage-job-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.usage-status-wrapper,
.usage-date {
  display: flex;
  align-items: center;
  justify-content: center;
}

.usage-radio {
  appearance: auto;
  accent-color: #FA4094;
  cursor: pointer;
}

.usage-table-footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.usage-row-setting {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.usage-row-label,
.usage-row-select {
  font-size: 16px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  color: #222;
}

.usage-row-select {
  border: none;
  padding: 8px;
  outline: none;
}

.usage-count-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.usage-pagination-wrapper {
  display: flex;
  align-items: center;
}

.usage-pagination-wrapper span {
  padding: 10px;
  cursor: pointer;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.usage-pagination-wrapper span:hover:not(.pagination-elep) {
  color: #FA4094;
}

.pagination-elep:hover,
.pagination-elipsis-front:hover {
    cursor: default;
    color: #000000!important;
}

.usage-arrow-prev,
.usage-arrow-next {
  display: flex;
  align-items: center;
  width: fit-content;
}

.usage-arrow-prev:hover svg path,
.usage-arrow-next:hover svg path {
  fill: #FA4094;
}

.usage-pagination-active {
  color: #FA4094;
  font-weight: 700;
  border-bottom: 1px solid #FA4094;
}

.usage-count-mobile {
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 0;
}
  
@media(max-width: 920px) {
  .usage-table-header,
  .usage-table-body {
    display: grid;
    grid-template-columns: 0.05fr 1fr 0.3fr 0.4fr;
  }
}

@media(max-width: 900px) {
  .usage-title-wrapper {
    grid-template-columns: 1fr 0.4fr;
  }
}

@media(max-width: 868px) {
  .usage-paragraph {
    grid-column: span 2;
  }
}

@media(max-width: 810px) {
  .usage-table-header,
  .usage-table-body {
    display: grid;
    grid-template-columns: 0.05fr 1fr 0.4fr 0.5fr;
  }
}

@media(max-width: 700px) {
  .usage-title-wrapper {
    grid-template-columns: 1fr 0.5fr;
  }
}

@media(max-width: 600px) {
  .usage-title-wrapper {
    grid-template-columns: 1fr;
  }

  .usage-paragraph {
    grid-column: span 1;
    order: 2;
  }

  .usage-table-header {
    display: none;
  }

  .usage-table-body {
    display: grid;
    grid-template-columns: 0.05fr 1fr 1fr;
  }
  
  .usage-radio-wrapper {
    grid-row: span 2  ;
    display: flex;
    align-items: center;
  }

  .usage-job-title {
    grid-column: span 2;
    overflow: visible;
    text-overflow: unset;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .usage-status-wrapper {
    order: 4;
    justify-content: flex-end;
  }

  .usage-date {
    order: 3;
    justify-content: flex-start;
  }

  .usage-row-setting {
    display: none;
  }

  .usage-count {
    display: none;
  }

  .usage-count-pagination,
  .usage-pagination-wrapper {
    width: 100%;
  }

  .usage-pagination-wrapper {
    justify-content: space-between;
  }

  .usage-count-mobile {
    display: flex;
    order: 3;
    font-size: 14px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-style: italic;
  }

  .usage-error-search {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
  }

  .usage-search-wrapper {
    order: 1;
    grid-column: span 2;
  }

  .usage-error {
    order: 2;
    font-size: 14px;
  }
}

